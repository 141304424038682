import { Extension } from '@tiptap/core'
import type { Editor } from '@tiptap/vue-3'
import { Plugin, PluginKey } from 'prosemirror-state'

// eslint-disable-next-line boundaries/element-types
import { useAttachments, type Attachment } from '~/features/attachments'

interface FileUploadOptions {
  allowedMimeTypes?: string[]
  maxImageSize?: number
  uploadImageToServer: (file: File) => Promise<Attachment[]>
}

export const FileUploadExtension = Extension.create<FileUploadOptions>({
  name: 'fileUpload',

  // Default configuration
  addOptions() {
    return {
      allowedMimeTypes: undefined as string[] | undefined,
      maxImageSize: undefined as number | undefined,
      uploadImageToServer: async (file: File) => {
        // Default is a no-op, user must configure this.
        const { attachmentsToUpload, uploadAttachments } = useAttachments()
        attachmentsToUpload.value = [file]
        return await uploadAttachments()
      }
    }
  },

  addProseMirrorPlugins() {
    const { editor } = this
    const { allowedMimeTypes, maxImageSize, uploadImageToServer } = this.options

    return [
      new Plugin({
        key: new PluginKey('fileUploadPlugin'),
        props: {
          handlePaste(view, event) {
            //@ts-expect-error
            const items = (event.clipboardData || event?.['originalEvent']?.clipboardData)?.items
            if (!items) {
              return false
            }

            for (const item of items) {
              if (item.kind === 'file') {
                const file = item.getAsFile()
                if (file && isAllowedFile(file, allowedMimeTypes, maxImageSize)) {
                  event.preventDefault()
                  uploadAndInsertImage(file, editor as Editor, uploadImageToServer)
                  return true
                }
              }
            }
            return false
          },
          handleDrop(view, event) {
            const files = event.dataTransfer?.files
            if (!files || files.length === 0) {
              return false
            }

            let handled = false
            for (const file of files) {
              if (isAllowedFile(file, allowedMimeTypes, maxImageSize)) {
                event.preventDefault()
                uploadAndInsertImage(file, editor as Editor, uploadImageToServer)
                handled = true
              }
            }
            return handled
          }
        }
      })
    ]
  }
})

function isAllowedFile(file: File, allowedMimeTypes?: string[], maxImageSize?: number): boolean {
  // Check MIME type
  if (allowedMimeTypes && !allowedMimeTypes.includes(file.type)) {
    console.warn(`File type ${file.type} not allowed.`)
    return false
  }

  // Check size
  if (typeof maxImageSize === 'number' && file.size > maxImageSize) {
    console.warn(`File size ${file.size} exceeds the limit of ${maxImageSize}.`)
    return false
  }

  // Check if it's an image
  if (!file.type.startsWith('image/')) {
    console.warn(`File is not an image: ${file.type}`)
    return false
  }

  return true
}

async function uploadAndInsertImage(
  file: File,
  editor: Editor,
  uploadImageToServer: (file: File) => Promise<Attachment[]>
) {
  try {
    const imageIds = await uploadImageToServer(file)
    const imageUrl = imageIds[0].originalUrl
    editor
      .chain()
      .focus()
      .addImage({
        src: imageUrl || '',
        'data-id': imageIds[0].id!
      })
      .createParagraphNear()
      .run()
  } catch (error) {
    console.error('Image upload failed:', error)
  }
}

export default FileUploadExtension
