import { useAttachmentsFetcher } from '~/shared/lib'

import { type Attachment } from '~/features/attachments'

export const uploadAttachment = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return useAttachmentsFetcher<Attachment>('uploader', {
    method: 'POST',
    endpoint: '',
    formData: true,
    body: formData
  })
}
