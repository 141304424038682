import { uploadAttachment } from '~/features/attachments'

// import uploadAt
export const useAttachments = () => {
  const attachmentsToUpload = ref<File[]>([])

  const uploadAttachments = async () => {
    const promises = []
    for (const file of attachmentsToUpload.value) {
      promises.push(uploadAttachment(file))
    }
    const results = await Promise.all(promises)
    return results.map((result) => result.data)
  }
  return {
    attachmentsToUpload,
    uploadAttachments
  }
}
