import { useUFetch, type BaseRequestParams } from '~/shared/lib'

type AttachmentsService = 'uploader' | 'variator'

export const useAttachmentsFetcher = <T>(service: AttachmentsService, params: BaseRequestParams) => {
  const config = useRuntimeConfig()
  const key = service === 'uploader' ? 'attachmentsUploaderApi' : 'attachmentsVariatorApi'
  const path = config.public[key]

  const { fetcher } = useUFetch(path)

  return fetcher<T>(params)
}
